@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@import url("./common.scss");
@import url("./loader.css");
@import url(./themes/menu.scss);
@import url(./themes/breadcrumb.scss);
@import url(./themes/drawer.scss);
@import url(./themes/modal.scss);

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--text-color-red: #ff4e4e;
	--text-color-grey: #e6e4e7;
	--text-color-ochre: #ffb600;
	--text-color-green: #3cc39a;
	--text-color-light-grey: #f5f4f5;
	--purpley-grey: #9b939f;
	--popover-padding: 5vh;
	--popover-padding-sm: 2vh;
	--page-margin: max(20px, calc((100vw - calc(335px + (1240 - 335) * (100vw - 375px) / (1440 - 375))) / 2));
	--grid-gutter: clamp(15px, calc(15px + (30 - 15) * (100vw - 375px) / (1024 - 375)), 30px);
	--section-gap-md: clamp(25px, calc(25px + (50 - 25) * (100vw - 375px) / (1440 - 375)), 50px);
	--spacing-unit: clamp(5px, calc(5px + (10 - 5) * (100vw - 375px) / (1440 - 375)), 10px);
	--font-size-24: 24px;
	--font-size-20: 20px;
	--font-size-18: 18px;
	--font-size-16: 16px;
	--font-size-14: 14px;
	--font-size-12: 12px;
	--font-size-10: 10px;
	--scrollbar-width: 14px;
	--sidebar-width: 240px;
	--bg-gray: #ececec;
}

* {
	font-family: "Inter", sans-serif;
}
@layer utilities {
	.custom-grid-md {
		display: -ms-grid;
		display: grid;
		grid-template-columns: repeat(12, minmax(0, 1fr));
		-webkit-column-gap: var(--grid-gutter);
		column-gap: var(--grid-gutter);
	}
	.custom-grid-md-colgap {
		-webkit-column-gap: var(--grid-gutter);
		column-gap: var(--grid-gutter);
	}
	.custom-grid-md-rowgap {
		row-gap: 0.5rem;
	}
	.scrollable-grid {
		display: -ms-grid;
		display: grid;
		-webkit-column-gap: var(--grid-gutter);
		column-gap: var(--grid-gutter);
		grid-auto-flow: column;
	}
	.shadow-search {
		-webkit-box-shadow:
			1.4vw 1.4vw 2.8vw 0 rgba(0, 0, 0, 0.03),
			0 0 1.4vw 0 rgba(0, 0, 0, 0.03);
		box-shadow:
			1.4vw 1.4vw 2.8vw 0 rgba(0, 0, 0, 0.03),
			0 0 1.4vw 0 rgba(0, 0, 0, 0.03);
	}
	.right-page-margin {
		right: var(--page-margin);
	}
	.height-bottom-btn-popover-100 {
		height: clamp(100px, calc(100px + (140 - 100) * (100vw - 375px) / (1440 - 375)), 140px);
	}

	.ant-modal-confirm.ant-modal-confirm-info {
		.ant-modal-content {
			@apply p-1.5 bg-gray-100;

			.ant-modal-confirm-body-wrapper {
				@apply grid place-items-center grid-cols-1;

				.ant-modal-confirm-body {
					.ant-modal-confirm-paragraph {
						@apply w-full  grid place-items-stretch;

						.ant-modal-confirm-title {
							@apply text-center text-gray-900 text-[15px] font-semibold font-['Inter'] leading-[18px] py-2.5;
						}

						.ant-modal-confirm-content {
							@apply bg-white rounded-lg ring-1 ring-gray-200 p-3 text-center text-gray-600 text-sm font-medium font-['Inter'] leading-5;
						}
					}
				}

				.ant-modal-confirm-btns {
					@apply mt-4 grid grid-cols-1 w-full p-3 pt-0;
				}
			}
		}
	}

	.scrollbar-hidden {
		&,
		.tui-full-calendar-timegrid-container {
			overflow-y: auto;
			scrollbar-width: none;
			-ms-overflow-style: none;
		}
		&::-webkit-scrollbar,
		.tui-full-calendar-timegrid-container::-webkit-scrollbar {
			display: none;
		}
		&::-webkit-scrollbar {
			display: none;
		}
	}

	@media all and (min-width: 768px) {
		.custom-grid-md-rowgap {
			row-gap: 0.75rem;
		}
	}
}

@layer components {
	.bg-dots {
		--dot: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='50%' cy='50%' r='0.5' fill='%239E9E9E' /%3E%3C/svg%3E");

		background-image: var(--dot), var(--dot);
		background-repeat: repeat, repeat;
		// background-position:
		// 	0 0,
		// 	0.3rem 0.3rem;
	}
}
* {
	scroll-behavior: smooth;
	-webkit-tap-highlight-color: transparent;
}
body {
	margin: 0;
	font-family: Inter;
	font-size: 14px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.mx-reset {
	margin-right: 0 !important;
	margin-left: 0 !important;
}
.my-reset {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}
.shadow-bottom {
	-webkit-box-shadow: 0 10px 10px -10px #ccc;
	box-shadow: 0 10px 10px -10px #ccc;
}
.shadow-top {
	-webkit-box-shadow: 0 -10px 10px -10px #ccc;
	box-shadow: 0 -10px 10px -10px #ccc;
}
.text-muted {
	color: #9b939f;
}

.disabled {
	opacity: 0.7;
	pointer-events: none;
}
.ptr {
	cursor: pointer;
}
.bg-gray {
	background: #f5f4f5;
}

/* Form Related styles start */
form .form-field {
	margin-bottom: 1rem;
}

form .form-field label {
	font-size: 0.9rem;
	font-weight: bold;
}
form .form-field label .description {
	display: block;
	font-size: 0.8rem;
	font-weight: normal;
	margin-top: 0.2rem;
	color: #9b939f;
}
form .form-field input,
select,
textarea {
	width: 100%;
	padding: 0.5rem;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-sizing: border-box;
	margin-top: 0.5rem;
}

form .form-field input:disabled,
select:disabled,
textarea {
	color: #9b939f;
}

form .form-field input:focus,
select:focus,
textarea:focus {
	outline: none;
}
/* Form Related styles end */
@keyframes blinking-dot {
	from {
		@apply bg-red-600;
	}
	to {
		@apply bg-amber-500;
	}
}

.blinking-dot {
	animation: blinking-dot 1s infinite cubic-bezier(1, 0.01, 0, 0.99);
	@apply w-2 h-2 rounded-full bg-red-600 border border-white border-solid ring-1 ring-gray-200;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	margin: 0;
}

#nprogress {
	.bar {
		@apply bg-primary-600;

		.peg {
			box-shadow:
				0 0 10px var(--tw-shadow-color),
				0 0 5px var(--tw-shadow-color);
			@apply shadow-primary-500;
		}
	}
	.spinner {
		.spinner-icon {
			@apply border-t-primary-600 border-l-primary-600 border-r-primary-600 size-5;
		}
	}
}

.ant-input-number.ant-input-number.ant-input-number.ant-input-number-disabled.ant-input-number-disabled {
	@apply bg-gray-100;
}

.ant-modal-confirm-body-wrapper {
	.ant-modal-confirm-body {
		span[role="img"] {
			display: none;
		}

		.ant-modal-confirm-title {
			@apply text-base font-medium text-gray-900 leading-snug;
		}
	}
}

.ant-tag {
	@apply inline-flex h-[22px] items-center gap-[5px] rounded-md text-xs font-medium leading-[15px] tracking-[0.01px];

	svg {
		@apply inline-block shrink-0;
	}
}

.ant-dropdown {
	.ant-table-filter-dropdown {
		.ant-dropdown-menu-root {
			@apply p-1;

			&::-webkit-scrollbar {
				display: none;
			}
		}
		.ant-table-filter-dropdown-btns {
			button {
				@apply h-6;
			}
		}
	}
}

.ant-upload-wrapper .ant-upload-list.ant-upload-list-text {
	.ant-upload-list-item-container {
		.ant-upload-list-item {
			@apply grid grid-cols-[auto,1fr,auto] items-center px-1 h-7 cursor-pointer rounded-md;

			.ant-upload-list-item-actions {
				button {
					@apply bg-white rounded-md p-1;

					&:has(.anticon.anticon-delete) {
						@apply bg-rose-100;

						.anticon.anticon-delete {
							@apply text-rose-600;
						}
					}
				}
			}
		}
	}
}
