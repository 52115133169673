.app-modal {
	.app-modal-content {
		@apply p-1.5 bg-gray-100;

		.app-modal-header {
			@apply bg-transparent py-2.5;

			.app-modal-title {
				@apply text-center text-gray-900 text-[15px] font-semibold font-['Inter'] leading-[18px];
			}
		}

		.app-modal-body {
			@apply bg-white rounded-lg ring-1 ring-gray-200;

			& .ant-upload-list {
				&::-webkit-scrollbar {
					display: none;
				}
			}
		}

		.app-modal-footer {
			@apply px-2.5 mt-4 pb-2.5;
		}
	}
}