.error {
	color: var(--text-color-red) !important;
}
.status--under_review,
.status--in_agent_review {
	color: var(--text-color-ochre) !important;
}
.status--total_payment_pending {
	color: var(--text-color-ochre) !important;
}
.status--changes_required {
	color: var(--text-color-red) !important;
}
.status--being_submitted,
.status--in_submission {
	color: var(--text-color-green) !important;
}
.status--submitted,
.status--in_submission {
	color: var(--text-color-green) !important;
}
.status--completed,
.status--final_result {
	color: var(--text-color-green) !important;
}
.h-200 {
	max-height: 200px;
}
.table-body-scrollable {
	max-height: 250px;
}
.status-text {
	word-break: break-word;
	width: 100px;
}
.hw-3 {
	width: 3rem !important;
	height: 3rem !important;
}
.hw-2 {
	width: 2.5rem;
	height: 2.5rem;
}
.muted {
	opacity: 0.3;
}
.border-top {
	border-top: 1px solid #ccc;
}
.no-focus:focus,
.no-focus:active {
	outline: none;
	border: none;
}
.no-btn:focus {
	outline: none;
}
.anchor {
	color: blue !important;
	cursor: pointer;
}
.table-bordered table tbody tr {
	border-bottom: 1px solid #ccc;
}
.tool-tip--popup {
	max-height: 100px;
}
.user-icon--placeholder {
	background-color: #ccc;
	height: 80px;
	width: 80px;
	border-radius: 50%;
}
.h-large {
	height: 650px;
}
.style-bullet {
	border: 1px solid #000000;
	background-color: #ffffff;
	height: 2rem;
	width: 2rem;
}
.border-bottom {
	border-bottom: 1px solid #ccc;
}
.color-white {
	color: #ffffff !important;
}
.border-right {
	border-right: 1px solid #ccc;
}
.border {
	border-color: #ccc;
}
.space-underline {
	padding-bottom: 5px;
	border-bottom-style: solid;
	border-bottom-width: 3.1px;
	border-color: #000000;
	width: fit-content;
}
.outline-none,
.outline-none:focus {
	outline: none;
}
#modal-root > * {
	transition: opacity 0.3s ease-in-out;
	opacity: 0;
}
#modal-root > *:last-child {
	opacity: 1;
}

.animate-shimmer-tag {
	--ease-in-1: cubic-bezier(0.25, 0, 1, 1);
	--ease-out-1: cubic-bezier(0, 0, 0.75, 1);
	--offset: 36px;
	@apply before:absolute [clip-path:border-box] before:w-1 before:h-16 before:text-white/50 before:bg-current before:blur-[4px] before:shadow-[14px_0_0_2px_currentColor] before:animate-[shimmer_infinite_5s] before:origin-[13px_50%] before:-top-1/2;

	@keyframes shimmer {
		0% {
			transform: rotate(31.583deg);
			left: calc(-1 * var(--offset));
			animation-timing-function: var(--ease-out-1);
		}
		50% {
			transform: scale(1.25) rotate(31.583deg);
			animation-timing-function: var(--ease-in-1);
		}
		100% {
			transform: rotate(31.583deg);
			left: calc(100% + 4px);
			animation-timing-function: var(--ease-in-1);
		}
	}
}

