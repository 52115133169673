#sentry-feedback {
	--font-family: "Inter", sans-serif;
	--font-size: 13px;
	--dialog-padding: 16px;
	--dialog-background: #f1f5f9;
	--dialog-color: #374151;
	--border: 1px solid #d1d5db;
	--input-border: 1px solid #d1d5db;
	--inset: auto auto 0 0;
	--box-shadow: 0px 0px 16px 0px rgba(15, 23, 42, 0.15);
}

