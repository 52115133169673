.logistic-tasks-listing.ant-table-wrapper.ant-table-wrapper.ant-table-wrapper {
	.ant-spin-nested-loading {
		.ant-spin {
			max-height: unset;
		}
	}

	.ant-table {
		thead.ant-table-thead {
			tr th.ant-table-cell {
				@apply bg-white whitespace-nowrap px-2 py-2.5 text-gray-700 text-[11px] font-semibold font-['Inter'] capitalize leading-[15px] border-b border-solid border-slate-300;

				svg {
					@apply text-gray-400;
				}
			}
		}

		tbody.ant-table-tbody {
			tr.ant-table-row {
				@apply border-none rounded-xl my-px;

				td.ant-table-cell {
					@apply cursor-pointer border-none whitespace-nowrap px-2 h-10 py-0 text-gray-600 text-[13px] font-normal font-['Inter'] leading-4;

					&:first-child {
						@apply rounded-l-xl;
					}

					&:last-child {
						@apply rounded-r-xl;
					}

					&.ant-table-cell-row-hover {
						& {
							@apply bg-gray-100;
						}
					}
				}
			}
		}
	}
}

