.editable-popover-content-container.editable-popover-content-container {
	@apply w-[320px];

	.editable-popover-content-title {
		position: relative;
		color: rgb(var(--gray-600-color));
		transform-style: preserve-3d;
		@apply text-base font-medium mb-1.5 h-6;
		transition: transform 0.4s ease-in-out;
		transition-delay: 0.3s;

		p {
			position: absolute;
			backface-visibility: hidden;
		}

		p:first-child {
			transform: translateZ(12px);
		}

		p:last-child {
			transform: rotateX(-90deg) translateZ(12px);
		}

		&.editable-popover-content-title-copied {
			transform: rotateX(90deg);
		}
	}

	textarea {
		&::-webkit-scrollbar {
			display: none;
		}
	}
	.ant-input-affix-wrapper {
		@apply rounded-md pr-2.5;
	}

	.ant-btn {
		@apply rounded-md;
	}
}

.ant-popover {
	.ant-popover-inner:has(.editable-popover-content-container) {
		@apply rounded-xl p-1 bg-gray-100 ring-gray-200 ring-1;
	}

	.ant-popover-inner:has(.editable-popover-content-container.editable-popover-content-container-warned) {
		animation: 0.4s ease wiggle;
	}
}

@keyframes wiggle {
	0% {
	}
	20% {
		transform: translateX(10px);
	}
	40% {
		transform: translateX(-10px);
	}
	60% {
		transform: translateX(10px);
	}
	80% {
		transform: translateX(-10px);
	}
	100% {
	}
}

